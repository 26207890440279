export const environment = {
  activatedRealms: [],
  production: true,
  version: '2.0.24',
  commitHash: '065b269d53911e3f5f579929c7dc620f39997b88',
  buildDate: '2024-10-25T08:19:53Z',
  versionUrl: 'https://gitlab.com/triarc-labs/sutter/sutterapp/-/network',
  sentry: 'https://0366305a84b94477945482bd19c939bb@o296516.ingest.sentry.io/5898911',
  // apiUrl: 'https://app.sutterbau.ch',
  apiUrl: 'http://localhost:5000',
  passwordChangeUrl:
    '/protocol/openid-connect/auth?client_id=sutter&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'sutter',
    bearerExcludedUrls: ['http://localhost:4200'],
    scope: 'openid email profile offline_access',
  },
  staging_colors_enabled: false,
  staging_toolbar_color: '#8c0d04',
  salesOrder: {
    viewDiscountPreAbacus: [9998],
    viewDiscountPostAbacus: [9998],
    viewDiscountSpecialPreAbacus: [],
    viewDiscountSpecialPostAbacus: [],
    canEditDiscountPreAbacus: [9998],
    canEditDiscountSpecialPreAbacus: [],
  },
  showSalesOrders: false,
  showTimeDifferenceOverview: true,

  features: {
    reportTab: true,
    documentTab: true,
  },
};
